<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <b-card no-body class="profile-container">
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">User Settings</h3>
              </div>
            </div>
            <b-tabs
              v-model="tabIndex"
              pills
              card
              vertical
              nav-wrapper-class="col-lg-3 col-md-3 col-sm-12 profileTab"
            >
              <b-tab title="My Profile" @click="clearFields">
                <div class="col-lg-12" v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </div>
                <div v-if="!loading">
                  <div class="row align-items-center">
                    <div class="col-lg-10 col-8">
                      <h3 class="mb-0">{{ moduleTitle }}</h3>
                    </div>
                    <div class="col-lg-2 col-4 text-right">
                      <a
                        @click="editAccount(accountButton)"
                        class="btn btn-m btn-primary text-white"
                        ><i :class="accountButtonIcon"> </i>
                        {{ accountButton }}</a
                      >
                    </div>
                  </div>

                  <div class="mt-4 pl-3 pt-2" v-show="!accountEdit">
                    <div class="row">
                      <div class="col-lg-6">
                        <ul
                          class="
                            list-group list-group-flush list
                            my--3
                            accountList
                          "
                        >
                          <li class="p-0" v-if="user.firstname">
                            <div class="row align-items-center">
                              <div class="col ml--2">
                                <h6 class="heading-smaller pb-0 mb-1 text-gray">
                                  First Name
                                </h6>
                                <h4 class="ml-1 mt-0 d-block h4">
                                  {{ user.firstname }}
                                </h4>
                              </div>
                            </div>
                          </li>

                          <li class="p-0">
                            <div class="row align-items-center">
                              <div class="col ml--2">
                                <h6
                                  class="
                                    heading-smaller
                                    pb-0
                                    mb-1
                                    mt-2
                                    text-gray
                                  "
                                >
                                  Email
                                </h6>
                                <h4 class="ml-1 mt-0 d-block h4">
                                  {{ user.email }}
                                </h4>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div class="col-lg-6" v-if="user.email">
                        <ul
                          class="
                            list-group list-group-flush list
                            my--3
                            accountList
                          "
                        >
                          <li class="p-0" v-if="user.lastname">
                            <div class="row align-items-center">
                              <div class="col ml--2">
                                <h6 class="heading-smaller pb-0 mb-1">
                                  Last Name
                                </h6>
                                <h4 class="ml-1 mt-0 d-block h4">
                                  {{ user.lastname }}
                                </h4>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <form v-show="accountEdit" @submit.prevent="updateProfile">
                    <div class="pl-lg-4 no-gutters mt-3">
                      <div class="row">
                        <div class="col-lg-6" v-if="user.firstname !== ''">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label
                              class="w-100 form-control-label"
                              for="firstname"
                              >First Name
                            </label>
                            <input
                              v-if="user.firstname !== ''"
                              type="text"
                              name="firstname"
                              v-model.trim="user.firstname"
                              @input="updateFirstName($event.target.value)"
                              data-vv-as="first name"
                              v-validate="'required'"
                              data-vv-name="firstname"
                              data-vv-delay="1000"
                              data-vv-scope="user"
                              placeholder="Full name"
                              autocomplete="noAutoComplete"
                              class="form-control no-instruction"
                              :class="{
                                input: true,
                                'is-invalid': errors.has('user.firstname'),
                                'has-invalid': errors.has('user.firstname'),
                              }"
                            />
                            <span class="has-icon-feedback"></span>
                            <div
                              class="invalid-feedback"
                              :is-invalid="errors.has('user.firstname')"
                            >
                              {{ errors.first("user.firstname") }}
                            </div>
                          </b-form-group>
                        </div>
                        <div class="col-lg-6" v-if="user.lastname !== ''">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label
                              class="w-100 form-control-label"
                              for="lastname"
                              >Last Name
                            </label>
                            <input
                              v-if="user.lastname !== ''"
                              type="text"
                              name="lastname"
                              v-model.trim="user.lastname"
                              @input="updateLastName($event.target.value)"
                              data-vv-as="last name"
                              v-validate="'required'"
                              data-vv-name="lastname"
                              data-vv-delay="1000"
                              data-vv-scope="user"
                              placeholder="last name"
                              autocomplete="noAutoComplete"
                              class="form-control no-instruction"
                              :class="{
                                input: true,
                                'is-invalid': errors.has('user.lastname'),
                                'has-invalid': errors.has('user.lastname'),
                              }"
                            />
                            <span class="has-icon-feedback"></span>
                            <div
                              class="invalid-feedback"
                              :is-invalid="errors.has('user.lastname')"
                            >
                              {{ errors.first("user.lastname") }}
                            </div>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="email"
                              >Email
                            </label>
                            <input
                              type="text"
                              name="email"
                              v-model.trim="user.email"
                              @input="updateEmail($event.target.value)"
                              v-validate="'required|email'"
                              data-vv-name="email"
                              data-vv-delay="1000"
                              data-vv-scope="user"
                              placeholder="Enter Email Address"
                              autocomplete="noAutoComplete"
                              class="form-control no-instruction"
                              :class="{
                                input: true,
                                'is-invalid': errors.has('user.email'),
                                'has-invalid': errors.has('user.email'),
                              }"
                            />
                            <span class="has-icon-feedback"></span>
                            <div
                              class="invalid-feedback"
                              :is-invalid="errors.has('user.email')"
                            >
                              {{ errors.first("user.email") }}
                            </div>
                          </b-form-group>
                        </div>
                        <div class="col-lg-6"></div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 text-right">
                          <button
                            id="profileUpdateSubmit"
                            type="button"
                            @click="onProfileUpdate"
                            v-promise-btn
                            class="btn btn-primary pull-right"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </b-tab>

              <b-tab v-if="false" title="Payment Settings" @click="clearFields">
                <div class="col-lg-12" v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-10 col-8">
                    <h3 class="mb-0">{{ moduleBillingTitle }}</h3>
                  </div>
                  <div class="col-lg-2 col-4 text-right">
                    <a
                      @click="editBilling(billingButton)"
                      class="btn btn-m btn-primary"
                      ><i :class="billingButtonIcon"> </i>
                      {{ billingButton }}</a
                    >
                  </div>
                </div>
                <div class="mt-2 pl-3 pt-2" v-show="!billingEdit">
                  <div class="row">
                    <div class="col-lg-12 no-gutters mt-4 pb-2 mb-2">
                      <h6 class="heading-small">Billing Information</h6>
                    </div>
                    <div class="col-lg-6">
                      <ul
                        class="
                          list-group list-group-flush list
                          my--3
                          billingList
                        "
                      >
                        <li class="p-0">
                          <div class="row align-items-center">
                            <div class="col ml--2">
                              <h6 class="heading-smaller pb-0 mb-1 text-gray">
                                Name on Card
                              </h6>
                              <h4
                                class="ml-2 mt-0 d-block h4"
                                v-if="billing.name"
                              >
                                {{ billing.name }}
                              </h4>
                              <div v-else class="mt-4"></div>
                            </div>
                          </div>
                        </li>
                        <li class="p-0">
                          <div class="row align-items-center">
                            <div class="col ml--2">
                              <h6 class="heading-smaller pb-0 mb-1 text-gray">
                                Email
                              </h6>
                              <h4
                                class="ml-1 mt-0 d-block h4"
                                v-if="billing.email"
                              >
                                {{ billing.email }}
                              </h4>
                              <div v-else class="mt-4"></div>
                            </div>
                          </div>
                        </li>
                        <li class="p-0">
                          <div class="row align-items-center">
                            <div class="col ml--2">
                              <h6 class="heading-smaller pb-0 mb-1 text-gray">
                                Phone
                              </h6>
                              <h4
                                class="ml-1 mt-0 d-block h4"
                                v-if="billing.phone"
                              >
                                {{ billing.phone }}
                              </h4>
                              <div v-else class="mt-4"></div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 no-gutters mt-4 pb-2 mb-2">
                      <h6 class="heading-small">Payment Information</h6>
                    </div>
                    <div
                      class="col-lg-7 col-md-7"
                      v-if="
                        billing.card.brand !== '' &&
                        billing.card.last4 !== '' &&
                        billing.card.exp_month !== '' &&
                        billing.card.exp_year !== '' &&
                        billing.card.address_zip !== ''
                      "
                    >
                      <div
                        class="col-lg-12 col-md-12 no-gutters"
                        v-if="
                          billing.card.brand !== '' &&
                          billing.card.last4 !== '' &&
                          billing.card.exp_month !== '' &&
                          billing.card.exp_year !== '' &&
                          billing.card.address_zip !== ''
                        "
                      >
                        <div class="col-lg-2 col-md-2 float-left no-gutters">
                          <span>
                            <i
                              class="fab fa-3x colordarkgray"
                              :class="billing.card.class"
                            ></i>
                          </span>
                          <span class="label bg-success text-white cctag"
                            >DEFAULT</span
                          >
                        </div>
                        <div class="col-lg-7 col-md-7 float-left">
                          <p>
                            <span
                              class="
                                pull-left
                                text-small
                                font-weight-bold
                                w-100
                              "
                              >(******* {{ billing.card.last4 }})</span
                            >
                            <span
                              class="
                                pull-left
                                heading-smaller
                                font-weight-bold
                                colordarkgray
                                w-100
                              "
                              >Expiration Date {{ billing.card.exp_month }}/{{
                                billing.card.exp_year
                              }}
                            </span>
                            <span
                              class="
                                pull-left
                                heading-smaller
                                font-weight-bold
                                colordarkgray
                                w-100
                              "
                              >Zip/Postal code
                              {{ billing.card.address_zip }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else class="col-lg-7 col-md-7 text-small">
                      No Credit Card in Your Account
                    </div>
                  </div>
                </div>
                <form id="setup-form" v-show="billingEdit">
                  <div class="pl-lg-4 no-gutters mt-3">
                    <div class="row">
                      <div class="col-lg-12">
                        <h6 class="heading-small mt-4">Billing Information</h6>
                      </div>
                      <div class="col-lg-6">
                        <b-form-group role="group" class="mb-3 has-feedback">
                          <label class="w-100 form-control-label" for="name"
                            >Name on Card
                          </label>
                          <input
                            type="text"
                            name="name"
                            v-model.trim="billing.name"
                            data-vv-as="full name"
                            v-validate="'required'"
                            data-vv-name="billing.name"
                            data-vv-delay="1000"
                            data-vv-scope="billing"
                            placeholder="Full name"
                            autocomplete="noAutoComplete"
                            class="form-control no-instruction"
                            :class="{
                              input: true,
                              'is-invalid': errors.has('billing.name'),
                              'has-invalid': errors.has('billing.name'),
                            }"
                          />
                          <span class="has-icon-feedback"></span>
                          <div
                            class="invalid-feedback"
                            :is-invalid="errors.has('billing.name')"
                          >
                            {{ errors.first("billing.name") }}
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-lg-6">
                        <b-form-group role="group" class="mb-3 has-feedback">
                          <label class="w-100 form-control-label" for="email"
                            >Email
                          </label>
                          <input
                            type="text"
                            name="email"
                            v-model.trim="billing.email"
                            v-validate="'required|email'"
                            data-vv-name="billing.email"
                            data-vv-as="email"
                            data-vv-delay="1000"
                            data-vv-scope="billing"
                            placeholder="Enter Email Address"
                            autocomplete="noAutoComplete"
                            class="form-control no-instruction"
                            :class="{
                              input: true,
                              'is-invalid': errors.has('billing.email'),
                              'has-invalid': errors.has('billing.email'),
                            }"
                          />
                          <span class="has-icon-feedback"></span>
                          <div
                            class="invalid-feedback"
                            :is-invalid="errors.has('billing.email')"
                          >
                            {{ errors.first("billing.email") }}
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6"></div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <b-form-group role="group" class="mb-3 has-feedback">
                          <label class="w-100 form-control-label" for="email"
                            >Phone
                          </label>
                          <input
                            type="text"
                            name="phone"
                            v-model.trim="billing.phone"
                            v-validate="'required'"
                            v-mask="'(###) ###-####'"
                            data-vv-name="billing.phone"
                            data-vv-as="phone"
                            data-vv-scope="billing"
                            placeholder="(999) 999-999"
                            autocomplete="noAutoComplete"
                            class="form-control no-instruction"
                            :class="{
                              input: true,
                              'is-invalid': errors.has('billing.phone'),
                              'has-invalid': errors.has('billing.phone'),
                            }"
                          />
                          <span class="has-icon-feedback"></span>
                          <div
                            class="invalid-feedback"
                            :is-invalid="errors.has('billing.phone')"
                          >
                            {{ errors.first("billing.phone") }}
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mt-4">
                        <h6 class="heading-small">Payment Information</h6>
                      </div>
                      <div class="col-lg-7">
                        <div class="row">
                          <div
                            class="col-lg-12 col-md-12 mb-3 mt-3"
                            v-if="
                              billing.card.brand !== '' &&
                              billing.card.last4 !== '' &&
                              billing.card.exp_month !== '' &&
                              billing.card.exp_year !== '' &&
                              billing.card.address_zip !== ''
                            "
                          >
                            <div
                              class="
                                col-lg-2 col-md-2
                                float-left
                                no-gutter-right
                              "
                            >
                              <span>
                                <i
                                  class="fab fa-3x colordarkgray"
                                  :class="billing.card.class"
                                ></i>
                              </span>
                              <span class="label bg-success text-white cctag"
                                >DEFAULT</span
                              >
                            </div>
                            <div class="col-lg-7 col-md-7 float-left">
                              <p>
                                <span
                                  class="
                                    pull-left
                                    text-small
                                    font-weight-bold
                                    w-100
                                  "
                                  >(******* {{ billing.card.last4 }})</span
                                >
                                <span
                                  class="
                                    pull-left
                                    heading-smaller
                                    font-weight-bold
                                    colordarkgray
                                    w-100
                                  "
                                  >Expiration Date
                                  {{ billing.card.exp_month }}/{{
                                    billing.card.exp_year
                                  }}
                                </span>
                                <span
                                  class="
                                    pull-left
                                    heading-smaller
                                    font-weight-bold
                                    colordarkgray
                                    w-100
                                  "
                                  >Zip/Postal code
                                  {{ billing.card.address_zip }}</span
                                >
                              </p>
                            </div>
                            <div
                              class="col-lg-2 col-md-2 float-left"
                              v-if="false"
                            >
                              <a class="btn btn-m btn-danger">Delete</a>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-12 col-md-12">
                            <b-form-group
                              role="group"
                              class="mb-3 has-feedback"
                              v-if="
                                (billing.card.brand === '' &&
                                  billing.card.last4 === '' &&
                                  billing.card.exp_month === '' &&
                                  billing.card.exp_year === '' &&
                                  billing.card.address_zip === '') ||
                                newCard
                              "
                            >
                              <label
                                class="w-100 form-control-label"
                                for="card-element"
                                >Credit Card
                              </label>
                              <div id="card-element">
                                <!-- Elements will create input elements here -->
                              </div>
                              <div id="cardholder-name"></div>
                              <div id="card-errors" role="alert"></div>
                            </b-form-group>

                            <div
                              class="col-lg-12 col-md-12 no-gutters"
                              v-if="
                                false &&
                                !newCard &&
                                billing.card.brand !== '' &&
                                billing.card.last4 !== '' &&
                                billing.card.exp_month !== '' &&
                                billing.card.exp_year !== '' &&
                                billing.card.address_zip !== ''
                              "
                            >
                              <a
                                class="btn btn-primary pull-left btn-m"
                                @click="enableNewCardEntry"
                                ><i class="fa fa-plus fa-fw"></i> Add New Credit
                                Card</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 text-right">
                        <button
                          type="submit"
                          ref="submitButtonRef"
                          id="card-button"
                          @click="addCard"
                          v-promise-btn
                          class="btn btn-primary pull-right"
                        >
                          Save Payment Settings
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </b-tab>

              <b-tab title="Change Password" @click="clearFields">
                <form>
                  <b-row>
                    <b-col md="6" class="order-md-last text-default strong">
                      <small class="font-weight-bold"
                        >Your password must not contain any blank spaces and
                        meet the following criteria:</small
                      >
                      <ul class="list-icon pt-3">
                        <li
                          :class="{
                            'icon-check-circle-o muted':
                              newPassword.length == 0,
                            'icon-check-circle valid': newPassword.length >= 8,
                            'icon-warning invalid':
                              newPassword.length < 8 && newPassword.length > 0,
                          }"
                        >
                          8 characters
                        </li>
                        <li
                          :class="{
                            'icon-check-circle-o muted':
                              hasLowerCase === false && hasUpperCase === false,
                            'icon-exclamation-circle warning':
                              (!hasLowerCase && hasUpperCase) ||
                              (hasUpperCase === false && hasLowerCase),
                            'icon-check-circle valid': hasLowercaseUppercase,
                            'icon-warning invalid':
                              hasLowercaseUppercase === false &&
                              newPassword.length > 0,
                          }"
                        >
                          Uppercase and lowercase letters
                        </li>
                        <li
                          :class="{
                            'icon-check-circle-o muted':
                              newPassword.length == 0,
                            'icon-check-circle valid': hasNumber,
                            'icon-warning invalid':
                              !hasNumber && newPassword.length > 0,
                          }"
                        >
                          Number(s)
                        </li>
                        <li
                          :class="{
                            'icon-check-circle-o muted':
                              newPassword.length == 0,
                            'icon-check-circle valid': hasSpecialChar,
                            'icon-warning invalid':
                              !hasSpecialChar && newPassword.length > 0,
                          }"
                        >
                          Special character(s)
                        </li>
                      </ul>
                      <div class="progress">
                        <div
                          class="progress-bar passwordMeter"
                          role="progressbar"
                          v-bind:style="{ width: progressWidth + '%' }"
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ progressLabel }}
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <b-form-group role="group" class="mb-3 has-feedback">
                        <p
                          v-show="hasError"
                          :class="{ 'is-invalid': hasError }"
                          class="form-feedback globalError"
                        >
                          {{ changePasswordError | capitalizeWords }}
                        </p>
                        <label
                          class="w-100 form-control-label"
                          for="oldPassword"
                          >Current Password
                          <small class="pull-right mr-1" v-if="oldPasswordShow"
                            ><a
                              ><i
                                @click="switchVisibility('oldPassword')"
                                class="text-gray"
                                :class="iconType.oldPassword"
                              >
                              </i></a></small
                        ></label>
                        <input
                          :type="passwordFieldType.oldPassword"
                          name="oldPassword"
                          v-model.trim="oldPassword"
                          @input="clearError($event.target.value)"
                          data-vv-as="current password"
                          v-validate="'required'"
                          data-vv-name="oldPassword"
                          data-vv-delay="1000"
                          data-vv-scope="account"
                          placeholder="Enter current password"
                          autocomplete="autocomplete off"
                          class="form-control no-instruction"
                          :class="{
                            input: true,
                            'is-invalid': errors.has('account.oldPassword'),
                            'has-invalid': errors.has('account.oldPassword'),
                          }"
                        />
                        <span class="has-icon-feedback"></span>
                        <div
                          class="invalid-feedback"
                          :is-invalid="errors.has('account.oldPassword')"
                        >
                          {{ errors.first("account.oldPassword") }}
                        </div>
                      </b-form-group>

                      <b-form-group role="group" class="mb-3 has-feedback">
                        <label
                          class="w-100 form-control-label"
                          for="newPassword"
                          >New Password
                          <small class="pull-right mr-1" v-if="newPassword"
                            ><a
                              ><i
                                @click="switchVisibility('newPassword')"
                                class="text-gray"
                                :class="iconType.newPassword"
                              ></i></a></small
                        ></label>
                        <input
                          :type="passwordFieldType.newPassword"
                          name="newPassword"
                          v-model.trim="newPassword"
                          @input="passwordCheck"
                          data-vv-as="new password"
                          v-validate="'required'"
                          data-vv-name="newPassword"
                          data-vv-delay="1000"
                          data-vv-scope="account"
                          placeholder="Enter new password"
                          autocomplete="autocomplete off"
                          class="form-control no-instruction"
                          :class="{
                            input: true,
                            'is-invalid': errors.has('account.newPassword'),
                            'has-invalid':
                              errors.has('account.newPassword') ||
                              (newPassword.length > 0 && progressWidth <= 40),
                            'has-valid':
                              progressWidth >= 75 &&
                              hasLowercaseUppercase &&
                              hasNumber &&
                              hasSpecialChar,
                            'has-warning':
                              newPassword.length > 0 &&
                              progressWidth > 40 &&
                              progressWidth < 75,
                          }"
                        />
                        <span class="has-icon-feedback"></span>
                        <div
                          class="invalid-feedback"
                          :is-invalid="errors.has('account.newPassword')"
                        >
                          {{ errors.first("account.newPassword") }}
                        </div>
                      </b-form-group>

                      <b-form-group role="group" class="mb-3 has-feedback">
                        <label
                          class="w-100 form-control-label"
                          for="confirmPassword"
                          >Confirm Password</label
                        >
                        <input
                          type="password"
                          name="confirmPassword"
                          v-model.trim="confirmPassword"
                          data-vv-as="password confirmation"
                          v-validate="
                            `required|confirmNewPassword:${newPassword}`
                          "
                          placeholder="Re-enter new password"
                          autocomplete="autocomplete off"
                          class="form-control no-instruction"
                          data-vv-name="confirmPassword"
                          data-vv-delay="1000"
                          data-vv-scope="account"
                          :class="{
                            input: true,
                            'is-invalid': errors.has('account.confirmPassword'),
                            'has-invalid': errors.has(
                              'account.confirmPassword'
                            ),
                            'has-valid':
                              !errors.has('account.confirmPassword') &&
                              confirmPassword.length >= 8,
                          }"
                        />
                        <span class="has-icon-feedback"></span>
                        <div
                          class="invalid-feedback"
                          :is-invalid="errors.has('account.confirmPassword')"
                        >
                          {{ errors.first("account.confirmPassword") }}
                        </div>
                      </b-form-group>

                      <div class="row form-action">
                        <div class="col-lg-5 col-md-5 col-12">
                          <button
                            ref="clear"
                            type="button"
                            name="button"
                            @click="clearFields"
                            class="btn btn-secondary pr-5 pl-5 w-sm-100"
                          >
                            Clear
                          </button>
                        </div>
                        <div class="col-lg-7 col-md-7 col-12">
                          <button
                            type="button"
                            name="button"
                            v-promise-btn
                            @click="onChangePassword"
                            class="
                              btn btn-primary
                              float-right
                              w-sm-100
                              mb-3 mb-md-0
                            "
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </form>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Cookies from "js-cookie";
import config from "config";
import { decoder } from "@/utils/sessions/jwt";
import { loadStripeCheckout } from "@/utils/loadStripe";
import { Validator } from "vee-validate";

Vue.use(Cookies);

const confirmNewPassword = {
  getMessage: (field) => `The password confirmation does not match`,
  validate(value, param) {
    return new Promise((resolve) => {
      resolve({
        valid: value.toString() === param[0],
        data:
          value === "" ? { message: `Confirm password field is required` } : "",
      });
    });
  },
};
Validator.extend("confirmNewPassword", confirmNewPassword);

export default {
  data() {
    return {
      tabIndex: 0,
      resetProfileData: {},
      user: {
        firstname: "",
        lastname: "",
        email: "",
      },
      billing: {
        customerID: "",
        name: "",
        email: "",
        phone: "",
        card: {
          class: "fa-cc-visa",
          brand: "",
          last4: "",
          address_zip: "",
          exp_month: "",
          exp_year: "",
        },
        paymentMethod: {},
      },
      profileLoaded: false,
      accountEdit: false,
      accountButton: "Edit",
      accountButtonIcon: "fas fa-edit",
      moduleTitle: "My Profile",

      billingEdit: false,
      billingButton: "Edit",
      billingButtonIcon: "fas fa-edit",
      moduleBillingTitle: "Payment Settings",

      passwordFieldType: {
        oldPassword: "password",
        newPassword: "password",
      },
      iconType: {
        oldPassword: "far fa-eye",
        newPassword: "far fa-eye",
      },
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      progressWidth: 0,
      progressLabel: "",
      hasNumber: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasLowercaseUppercase: false,
      hasSpecialChar: false,
      hasPeriodDash: false,
      hasError: false,
      changePasswordError: "",
      oldPasswordShow: false,
      newPasswordShow: false,
      clientSecret: false,
      stripe: null,
      card: null,
      cardError: false,
      newCard: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
    currentUserGroup() {
      return this.$store.state.user.userGroup || null;
    },
    stripeForm() {
      return document.getElementById("setup-form");
    },
    stripeStyle() {
      return {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "15px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          fontSize: "14px",
          color: "#f4516c",
          iconColor: "#f4516c",
        },
      };
    },
  },
  methods: {
    getProfile() {
      this.$store
        .dispatch("user/USER_PROFILE_REQUEST")
        .then((resp) => {
          this.resetProfileData = this.$store.state.user.user;
          this.user.fullName = this.$store.state.user.fullName || "";
          this.user.firstname =
            typeof this.$store.state.user !== "undefined"
              ? this.$store.state.user.firstname
              : "";
          this.user.lastname =
            typeof this.$store.state.user !== "undefined"
              ? this.$store.state.user.lastname
              : "";
          this.user.email = this.$store.state.user.email || "";
          // this.user.phone = this.$store.state.user.phone || this.$store.state.user.user.profile.phone || ''
          // this.clientSecret = this.$store.state.user.billingInfo.clientSecret || ''

          /*if (Object.keys(this.$store.state.user.billingInfo).length > 0) {
                var brand = this.$store.state.user.billingInfo.card.brand || ''
                this.billing.customerID = this.$store.state.user.billingInfo.customerID || ''
                this.billing.name = this.$store.state.user.billingInfo.name || ''
                this.billing.email = this.$store.state.user.billingInfo.email || ''
                this.billing.phone = this.$store.state.user.billingInfo.phone || ''
                this.billing.card = {
                  class: this.getCardBrands(brand || 'visa'),
                  brand: brand || '',
                  last4: this.$store.state.user.billingInfo.card.last4 || '',
                  address_zip: this.$store.state.user.billingInfo.card.address_zip || '',
                  exp_month: this.$store.state.user.billingInfo.card.exp_month || '',
                  exp_year: this.$store.state.user.billingInfo.card.exp_year || ''
                }
              }*/
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCardBrands(brand) {
      // brands stripe, visa, paypal, mastercard, jcb, discover, apple-pay, amex, amazon-pay
      return `fa-cc-${brand}`;
    },
    editAccount(accountButton) {
      if (accountButton === "Edit") {
        this.accountButton = "Cancel";
        this.accountButtonIcon = "ni ni-fat-removeCancel";
        this.moduleTitle = "Edit My Profile";
        this.accountEdit = true;
      } else {
        this.accountButton = "Edit";
        this.accountButtonIcon = "fas fa-edit";
        this.moduleTitle = "My Profile";
        this.accountEdit = false;
        this.cancelUpdateAccount();
      }
    },
    editBilling(billingButton) {
      if (billingButton === "Edit") {
        this.billingButton = "Cancel";
        this.billingButtonIcon = "ni ni-fat-removeCancel";
        this.moduleBillingTitle = "Edit Payment Settings";
        this.billingEdit = true;
        this.newCard = false;
      } else {
        this.billingButton = "Edit";
        this.billingButtonIcon = "fas fa-edit";
        this.moduleBillingTitle = "Payment Settings";
        this.billingEdit = false;
        this.newCard = false;
        // this.cancelUpdateAccount()
      }
    },
    cancelUpdateAccount() {
      const resetData = this.resetProfileData;

      this.accountButton = "Edit";
      this.accountButtonIcon = "fas fa-edit";
      this.moduleTitle = "My Profile";
      this.accountEdit = false;

      this.user.email = resetData.email || "";
      this.user.firstname = resetData.firstname || "";
      this.user.lastname = resetData.lastname || "";

      this.updateFirstName(resetData.firstname);
      this.updateLastName(resetData.lastname);
      this.updateEmail(resetData.email);
    },
    onProfileUpdate() {
      const promiseBtn = document.getElementById("profileUpdateSubmit");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      return this.$validator.validateAll("user").then((isValid) => {
        if (isValid) {
          const { email, firstname, lastname } = this.user;
          this.$store
            .dispatch("user/UPDATE_USER_PROFILE", {
              email,
              firstname,
              lastname,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    res.data.message
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });

                this.accountButton = "Edit";
                this.accountButtonIcon = "fas fa-edit";
                this.moduleTitle = "My Profile";
                this.accountEdit = false;
                this.getProfile();
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Error updating profile"
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
              }
              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
            })
            .catch((err) => {
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.cancelUpdateAccount();

                // this.changePasswordError = error.errMessage
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "danger",
                });
                this.hasError = true;
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
              });
            });
        }
        // this.getProfile()
      });
    },
    passwordCheck: function () {
      const password = this.newPassword;
      this.hasNumber = /\d/.test(password);
      this.hasUpperCase = /[A-Z]/.test(password);
      this.hasLowerCase = /[a-z]/.test(password);
      this.hasLowercaseUppercase =
        /[a-z]/.test(password) && /[A-Z]/.test(password);
      this.hasSpecialChar = /[!@#/$%^&*)(+=#><;:,._-]/.test(password);
      this.hasPeriodDash = !/^\s*?\.|-.*$/.test(password);
      this.level = 0;

      var progressBar = document.querySelectorAll(".passwordMeter");
      var charCounts = {};
      var numCount = 0;
      var upperCaseCount = 0;
      var specialCharCount = 0;

      for (var i = 0; i < password.length; i++) {
        if (/\d/.test(password.charAt(i))) numCount++;
        if (/[A-Z]/.test(password.charAt(i))) upperCaseCount++;
        if (/[!@#/$%^&*)(+=#><;:,._-]/.test(password.charAt(i)))
          specialCharCount++;
      }

      charCounts.numCount = numCount;
      charCounts.ucCount = upperCaseCount;
      charCounts.scCount = specialCharCount;

      this.progressWidth = this.calcPassStrength(
        password.length,
        this.hasNumber,
        this.hasLowercaseUppercase,
        charCounts,
        this.hasSpecialChar,
        this.hasPeriodDash
      );

      if (this.progressWidth <= 40) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-success");
          el.classList.add("bg-danger");
        });
        this.progressLabel = this.progressWidth >= 30 ? "Bad Password" : "Bad";
        this.$validator.errors.add({
          field: "newPassword",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth > 40 && this.progressWidth < 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-danger");
          el.classList.remove("bg-success");
          el.classList.add("bg-warning");
        });
        this.progressLabel = "Weak Password";
        this.$validator.errors.add({
          field: "newPassword",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth >= 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-danger");
          el.classList.add("bg-success");
        });
        this.progressLabel = "Strong Password";

        this.$nextTick(() => {
          this.$validator.errors.remove("newPassword", "account");
        });
      }
    },
    calcPassStrength: function (
      len,
      num,
      lowCap,
      charCounts,
      specialChar,
      periodDash
    ) {
      var level = 0;
      if (len >= 8) {
        level += 18;
      }
      if (charCounts.ucCount === 2) {
        level += 10;
      }
      if (charCounts.ucCount > 2) {
        level += 4;
      }
      if (num) {
        level += 12;
      }
      if (charCounts.numCount > 2) {
        level += 5;
      }
      if (lowCap) {
        level += 22;
      }
      if (specialChar) {
        level += 23;
      }
      if (charCounts.scCount === 2) {
        level += 10;
      }
      if (charCounts.scCount >= 3) {
        level += 12;
      }
      // if (periodDash) { level += 10 }
      if (len === 0) {
        level = 0;
      }
      // console.log(len)
      // console.log(level)
      return level;
    },
    onChangePassword() {
      return this.$validator.validateAll("account").then((isValid) => {
        if (isValid) {
          const { oldPassword, newPassword } = this;
          const password = { oldPassword, newPassword };
          const token = "";
          return this.$store
            .dispatch("user/CHANGE_PASSWORD_REQUEST", { password, token })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    res.data.message
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                this.oldPassword =
                  this.newPassword =
                  this.confirmPassword =
                  this.changePasswordError =
                  this.progressLabel =
                    "";
                this.progressWidth = 0;
                this.hasNumber =
                  this.hasUpperCase =
                  this.hasLowerCase =
                  this.hasLowercaseUppercase =
                  this.hasSpecialChar =
                  this.hasPeriodDash =
                  this.hasError =
                    false;
                this.$validator.reset();
                this.$validator.errors.clear("account");
                this.errors.clear("account");
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    res.data.message
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
              }
            })
            .catch((err) => {
              err.then((error) => {
                // this.changePasswordError = error.errMessage
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
              });
            });
        }
      });
    },
    clearError(val) {
      if (val.length > 0) {
        this.hasError = false;
      }
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword =
        this.newPassword =
        this.confirmPassword =
        this.changePasswordError =
        this.progressLabel =
          "";
      this.progressWidth = 0;
      this.hasNumber =
        this.hasUpperCase =
        this.hasLowerCase =
        this.hasLowercaseUppercase =
        this.hasSpecialChar =
        this.hasPeriodDash =
        this.hasError =
          false;
      this.cancelUpdateAccount();
    },
    switchVisibility(field) {
      this.passwordFieldType[field] =
        this.passwordFieldType[field] === "password" ? "text" : "password";
      this.iconType[field] =
        this.iconType[field] === "far fa-eye"
          ? "far fa-eye-slash"
          : "far fa-eye";
    },
    updateFirstName(newFirstName) {
      this.$root.$store.state.user.firstname = newFirstName;
    },
    updateLastName(newLastName) {
      this.$root.$store.state.user.lastname = newLastName;
    },
    updateFullName(newFullName) {
      this.$root.$store.state.user.fullName = newFullName;
    },
    updateEmail(newEmail) {
      this.$root.$store.state.user.email = newEmail;
    },
    changeLoadingState(isLoading) {
      const promiseBtn = document.getElementById("card-button");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      const bodyWrapper = document.getElementById("setup-form");

      if (isLoading) {
        promiseBtnInner.classList.remove("hidden");
        promiseBtn.setAttribute("disabled", "disabled");
        bodyWrapper.classList.add("non-clickable");
      } else {
        promiseBtnInner.classList.add("hidden");
        promiseBtn.removeAttribute("disabled", "disabled");
        bodyWrapper.classList.remove("non-clickable");
      }
    },
    addCard() {
      if (
        this.billing.card.brand === "" &&
        this.billing.card.last4 === "" &&
        this.billing.card.exp_month === "" &&
        this.billing.card.exp_year === "" &&
        this.billing.card.address_zip === ""
      ) {
        let self = this;
        const { name, email, phone, billing } = this.billing;

        return this.$validator.validateAll("billing").then((isValid) => {
          if (isValid && !this.cardError) {
            this.changeLoadingState(true);
            this.billing.billing = true; // need this to identify if the update is for billing data
            this.stripe
              .createPaymentMethod({
                type: "card",
                card: self.card,
                billing_details: {
                  name: name,
                  email: email,
                  phone: phone,
                },
              })
              .then((result) => {
                if (result.error) {
                  this.showCardError(result.error);
                  this.changeLoadingState(false);
                } else {
                  this.billing.paymentMethod = result.paymentMethod;
                  this.createStripeCustomer();
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        });
      } else {
        this.billing.paymentMethod = false; // need this to be false in order to not to create new user in stripe
        this.billing.billing = true; // need this to identify if the update is for billing data
        this.createStripeCustomer();
      }
    },
    showCardError(error) {
      this.changeLoadingState(false);
      // The card was declined (i.e. insufficient funds, card has expired, etc)
      const errorMsg = document.getElementById("card-errors");
      errorMsg.textContent = error.message;

      setTimeout(function () {
        errorMsg.textContent = "";
      }, 8000);
    },
    createStripeCustomer() {
      const { name, email, phone, billing, paymentMethod, customerID } =
        this.billing;

      this.$store
        .dispatch("user/UPDATE_USER_PROFILE", {
          name,
          email,
          phone,
          billing,
          paymentMethod,
          customerID,
        })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(res.data.message),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });

            this.billingButton = "Edit";
            this.billingButtonIcon = "fas fa-edit";
            this.moduleBillingTitle = "Payment Settings";
            this.billingEdit = false;
            this.getProfile();
            this.changeLoadingState(false);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error updating billing and credit card information"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.getProfile();
            this.changeLoadingState(false);
          }
        })
        .catch((err) => {
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.changeLoadingState(false);
            this.getProfile();
          });
        });
    },
    submit() {
      this.$refs.submitButtonRef.click();
    },
    enableNewCardEntry() {
      this.newCard = true;
      setTimeout(() => {
        this.card.mount("#card-element");

        this.card.addEventListener("change", ({ error }) => {
          const displayError = document.getElementById("card-errors");
          if (error) {
            displayError.textContent = error.message;
            self.cardError = true;
          } else {
            displayError.textContent = "";
            self.cardError = false;
          }
        });
      }, 1000);
    },
  },
  mounted() {
    const token = Cookies.get("user-token");
    const companyID = Cookies.get("companyID") || "";
    const decode = decoder({
      secret: config.secret,
    });
    var payload = decode(token);

    this.getProfile();

    if (payload.userGroup !== 4) {
      var self = this;
      const appStripePK =
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "staging"
          ? process.env.appStripePKTestKey ||
            process.env.VUE_APP_STRIPE_PK_TEST_KEY
          : process.env.VUE_APP_STRIPE_PK_LIVE_KEY;

      /*loadStripeCheckout(appStripePK, 'v3', () => {
          const submitButton = document.getElementById('card-button')
          this.stripe = Stripe(appStripePK)

          if (this.currentUserGroup && this.billing.card.brand === '' && this.billing.card.last4 === '' && this.billing.card.exp_month === '' && this.billing.card.exp_year === '' && this.billing.card.address_zip === '') {
            this.card = this.stripe.elements().create('card', {style: this.stripeStyle})
            this.card.mount('#card-element')

            this.card.addEventListener('change', ({ error }) => {
              const displayError = document.getElementById('card-errors')
              if (error) {
                displayError.textContent = error.message
                self.cardError = true
              } else {
                displayError.textContent = ''
                self.cardError = false
              }
            })
          }


          /*this.stripeForm.addEventListener('submit', async (event) => {
            event.preventDefault()

          })*/

      /*this.stripeForm.addEventListener('submit', async (event) => {
            try {
              this.$emit('loading', true)

              event.preventDefault()

              const data = {
                ...this.card
              }
              console.log(this.card)
              /*
              if (this.amount) data.amount = this.amount

              const { token, error } = await this.stripe.createToken(data)

              if (error) {
                const errorElement = document.getElementById('card-errors')
                errorElement.textContent = error.message;
                console.error(error)
                this.$emit('error 1', error)
              } else {
                this.$emit('token', token)
              }
            } catch (error) {
              console.error(error);
              this.$emit('error', error)
            } finally {
              this.$emit('loading', false)
            }
          })*/

      //})

      if (this.$route.query.view === "payment") {
        this.tabIndex = 1;
      }
    }
  },
  watch: {
    oldPassword(value) {
      if (value.length > 0) {
        this.oldPasswordShow = true;
      } else {
        this.oldPasswordShow = false;
      }
    },
    newPassword(value) {
      if (value.length > 0) {
        this.newPasswordShow = true;
      } else {
        this.oldPasswordShow = false;
      }
    },
  },
};
</script>
<style lang="scss">
.profile-container {
  min-height: 550px;
}
.profile .nav-pills .nav-item:not(:last-child) {
  padding-right: 0 !important;
  margin-bottom: 5px;
}
.profile .progress {
  height: 0.95rem;
}
.profile .promise-btn__spinner-wrapper {
}
.profileTab .card-header {
  border-bottom: none !important;
}
.heading-smaller {
  font-size: 0.71rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.billingList li,
.accountList li {
  list-style-type: none;
  padding-bottom: 10px !important;
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #f4516c !important;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
#card-element {
  border: 1px solid #adb5bd;
}
#card-errors {
  color: #f4516c !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 13px !important;
}
.colordarkgray {
  color: #4d4e53;
}
.cctag {
  float: left;
  padding: 2px 3px;
  font-size: 11px !important;
  border-radius: 3px !important;
}
@media only screen and (max-width: 600px) {
  .profileTab {
    width: 100% !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4d4a4a !important;
}
</style>
